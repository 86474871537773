(function ($) {

	$(function () {

		/* Functions */
		const handleFirstTab = (e) => {
			if (e.keyCode === 9) { // the "I am a keyboard user" key
				document.body.classList.add('user-is-tabbing');
			}
		};

		const handleFirstClick = (e) => {
			document.body.classList.remove('user-is-tabbing');
		};

		window.addEventListener('keydown', handleFirstTab);
		window.addEventListener('click', handleFirstClick);

	});

	$(document).ready(function () {

		// Select all links with hashes
		$('a.smooth-scroll[href*="#"]')
			// Remove links that don't actually link to anything
			.not('[href="#"]').not('[href="#0"]').click(function (event) {
			// On-page links
			if (
				location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
				&&
				location.hostname == this.hostname
			) {
				// Figure out element to scroll to
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top - 150,
					}, 1000, function () {
						// Callback after animation
						// Must change focus!
						var $target = $(target);
						$target.focus();
						if ($target.is(':focus')) { // Checking if the target was focused
							return false;
						} else {
							$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
							$target.focus(); // Set focus again
						}
					});
				}
			}
		});

		// Check for euroland iframes
		$('iframe').each(function () {
			let $iframe = $(this);
			let src = $iframe.attr('src');

			// Check if the src contains 'euroland.com'
			if (src && (src.includes('euroland.com') || src.includes('eurolandir.com'))) {
				// Check if the EurolandIFrameAutoHeight is available
				if (typeof $iframe.EurolandIFrameAutoHeight === 'function') {
					// Do auto height
					$iframe.EurolandIFrameAutoHeight();
				} else {
					// If autoheight was not available load the euroland script,
					// and try to trigger EurolandIFrameAutoHeight() again
					$.getScript('https://tools.euroland.com/tools/common/eurolandiframeautoheight/eurolandtoolsintegrationobject.js')
						.done(function () {
							// Do auto height
							$iframe.EurolandIFrameAutoHeight();
						})
						.fail(function () {
							console.error('Failed to load EurolandIFrameAutoHeight script.');
						});
				}
			}
		});

	}); // .END Doc Ready
})(jQuery);
